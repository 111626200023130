<template>
  <div class="container-xl px-0 px-sm-3">
    <div class="encabezado-pagina align-items-sm-start">
      <h2 class="col user-select-none">Configuración</h2>
      <div class="col-auto my-2 my-sm-0 resaltado-usuario">
        <div class="row align-items-center mx-0 text-left">
          <div class="col-auto px-0">
            <font-awesome-icon icon="user-circle" size="2x" />
          </div>
          <p class="col mb-0">
            <span
              :class="['d-block user-select-none text-uppercase mb-n1',
                {'text-muted font-italic': !(cliente.nombre && cliente.nombre.length)}]"
            >
              {{ cliente.nombre && cliente.nombre.length ? cliente.nombre : "Sin especificar" }}
            </span>
            <small
              :class="['usuario', {'text-muted font-italic user-select-none': !cliente.usuario}]"
              :title="`Usuario: ${cliente.usuario ? cliente.usuario : 'Sin especificar'}`"
            >
              {{ cliente.usuario ? cliente.usuario : "Sin especificar" }}
            </small>
            <small
              :class="['d-block', {'text-muted font-italic user-select-none': !cliente.casillero}]"
              :title="`Casillero: ${cliente.casillero ? cliente.casillero : 'Sin especificar'}`"
            >
              {{ cliente.casillero ? cliente.casillero : "Sin especificar" }}
            </small>
          </p>
        </div>
      </div>
    </div>
    <div class="mb-page">
      <ul class="lista-configuracion">
        <li
          v-for="(item, index) in listaConfiguracion"
          :key="index"
          :class="{'cursor-not-allowed': item.deshabilitar}"
        >
          <div
            :class="['contenedor-item', {'disabled': item.deshabilitar}]"
            @click="ejecutar(item.accion)"
          >
            <font-awesome-icon :icon="item.icono" class="icono" />
            {{item.titulo}}
          </div>
        </li>
      </ul>
    </div>
    <!-- Modal Cambio de usuario -->
    <mdb-modal
      centered
      elegant
      scrollable
      :show="mostrarFormularioCambioUsuario"
      @close="mostrarFormularioCambioUsuario = false; limpiarDatosModalInformacionDeContacto()"
    >
      <mdb-modal-header>
        <mdb-modal-title>Cambio de usuario</mdb-modal-title>
      </mdb-modal-header>
      <mdb-modal-body>
        <div v-if="cliente && cliente.emails && cliente.emails.length" class="mt-n3">
          <div v-if="emailEditarSeleccionado">
            <mdb-input
              v-model.trim="emailAEditar.emailNuevo"
              type="email"
              label="Email"
              class="my-2"
              outline
              @keyup.enter.native="validarEmail(emailAEditar.emailNuevo, false, true)"
            />
            <div class="mb-2 mt-3 text-center">
              <mdb-btn
                color="exitoso"
                icon="sync"
                title="Actualizar email"
                class="m-1 py-2 px-3"
                :disabled="botonDeshabilitado"
                @click="validarEmail(emailAEditar.emailNuevo, false, true)"
              >
                Actualizar
              </mdb-btn>
              <mdb-btn
                flat
                dark-waves
                icon="times"
                title="Cancelar edición del email"
                class="m-1 p-2 px-md-3"
                @click="emailEditarSeleccionado = false;"
              >
                Cancelar
              </mdb-btn>
            </div>
          </div>
          <div v-else class="mb-2">
            <small class="text-muted user-select-none">Usuario actual</small>
            <span class="d-block">
              {{ cliente.emails[0].email }}
            </span>
            <mdb-btn
              block
              flat
              dark-waves
              icon="pen"
              title="Editar email"
              class="m-1 mt-3 py-1 px-3"
              @click="emailEditarSeleccionado = true;
                emailAEditar = {
                  emailIndice: 0,
                  emailNuevo: cliente.emails[0].email,
                  emailAnterior: cliente.emails[0].email
                };"
            >
              Editar
            </mdb-btn>
          </div>
        </div>
      </mdb-modal-body>
    </mdb-modal>
    <!-- Modal Cambio clave -->
    <ModalCambiarClave
      :clienteId="clienteId"
      :mostrarModal="mostrarModalCambioClave"
      @cerrar="mostrarModalCambioClave = false"
      @alertaMensaje="alertaMensaje = $event"
    />
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </div>
</template>
<script>
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
  mdbInput
} from 'mdbvue'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import clienteGql from '@/graphql/cliente.gql'
import { validarMail } from '@/funciones/funciones.js'
import usuarioCambiarGql from '@/graphql/usuarioCambiar.gql'
import ModalCambiarClave from '@/components/ModalCambiarClave.vue'
import { leerPersonaId } from '@/utils/datosToken.js'
export default {
  name: 'Configuración',
  components: {
    AlertaMensaje,
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    mdbInput,
    ModalCambiarClave
  },
  data () {
    return {
      cliente: {},
      // Modal cambiar clave
      mostrarModalCambioClave: false,
      // Modal cambiar usuario
      mostrarFormularioCambioUsuario: false,
      // Emails
      mostrarNuevoEmail: false,
      editarEmail: false,
      emailEditarSeleccionado: false,
      emailAEditar: {
        emailIndice: 0,
        emailAnterior: '',
        emailNuevo: ''
      },
      // Cliente
      clienteId: '',
      // Otros
      alertaMensaje: { contenido: '' },
      botonDeshabilitado: false,
      listaConfiguracion: [
        {
          accion: 'CambioClave',
          icono: 'lock',
          titulo: 'Cambio de clave',
          deshabilitar: false
        },
        {
          accion: 'CambioUsuario',
          icono: 'people-arrows',
          titulo: 'Cambio de usuario',
          deshabilitar: false
        }
      ]
    }
  },
  mounted () {
    this.clienteId = leerPersonaId()
  },
  methods: {
    // Funcionalidad AlertaMensaje
    mostrarMensajeEnCasoDefault () {
      this.alertaMensaje = {
        contenido: 'Ha ocurrido un error inesperado. Por favor intenta de nuevo',
        tipo: 'error'
      }
    },
    ejecutar (accion) {
      switch (accion) {
        case 'CambioClave':
          this.mostrarModalCambioClave = true
          break
        case 'CambioUsuario':
          this.mostrarFormularioCambioUsuario = true
          break
        default:
          break
      }
    },
    limpiarDatosModalInformacionDeContacto () {
      // Emails
      this.mostrarNuevoEmail = false
      this.editarEmail = false
      this.emailEditarSeleccionado = false
      this.emailAEditar = {
        emailIndice: 0,
        emailAnterior: '',
        emailNuevo: ''
      }
    },
    // Funcionalidad emails
    validarEmail (email) {
      this.botonDeshabilitado = true
      if (email === this.emailAEditar.emailAnterior) {
        (this.alertaMensaje = {
          contenido: 'El email no puede ser idéntico al anterior',
          tipo: 'error'
        })
        this.botonDeshabilitado = false
        return
      }
      if (!email || !validarMail(email)) {
        (this.alertaMensaje = {
          contenido: 'Email no válido',
          tipo: 'error'
        })
        this.botonDeshabilitado = false
        return
      }
      return this.usuarioCambiar(email)
    },
    // Funcionalidad usuario
    usuarioCambiar (email) {
      this.botonDeshabilitado = true
      const id = this.clienteId
      this.$apollo.mutate({
        mutation: usuarioCambiarGql,
        variables: {
          usuarioAnterior: this.emailAEditar.emailAnterior,
          propietarioId: id,
          usuarioNuevo: email
        },
        update: (store, { data: { usuarioCambiar } }) => {
          const data = store.readQuery({
            query: clienteGql,
            variables: {
              filter: {
                id
              }
            }
          })
          const msg = usuarioCambiar.codigo
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido: 'Se ha actualizado el usuario exitosamente',
                tipo: 'correcto'
              }
              break
            case 'Fallido':
              this.botonDeshabilitado = false
              return (this.alertaMensaje = {
                contenido: 'Ha ocurrido un error actualizando el usuario. Por favor intenta de nuevo',
                tipo: 'error'
              })
            default:
              this.botonDeshabilitado = false
              return this.mostrarMensajeEnCasoDefault()
          }
          data.Cliente[0].emails.forEach(mail => {
            if (this.emailAEditar.emailAnterior === mail.email) {
              mail.email = this.emailAEditar.emailNuevo
            }
          })
          data.Cliente[0].usuario = this.emailAEditar.emailNuevo
          store.writeQuery({
            query: clienteGql,
            variables: {
              filter: {
                id
              }
            },
            data
          })
          if (!this.botonDeshabilitado) {
            this.editarEmail = false
            this.emailEditarSeleccionado = false
          }
          this.botonDeshabilitado = false
        },
        optimisticResponse: {
          __typename: 'Mutation',
          usuarioCambiar: {
            __typename: 'Resultado',
            codigo: 'Correcto'
          }
        }
      })
        .catch(() => {
          this.mostrarMensajeEnCasoDefault()
          this.botonDeshabilitado = false
        })
    }
  },
  apollo: {
    cliente () {
      return {
        query: clienteGql,
        variables () {
          return {
            filter: {
              id: leerPersonaId()
            }
          }
        },
        update: (data) => data.Cliente[0],
        fetchPolicy: 'cache-and-network'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.resaltado-usuario {
  background-color: lighten(rgb(140, 140, 140, .3), 25%);
  border-radius: 35px;
  color: $oscuro;
  padding: .1rem .75rem .25rem;

  .usuario {
    display: block;
    max-width: 162px;
    overflow: hidden;
    text-overflow: ellipsis;
    user-select: all;
    white-space: nowrap;
  }
}
.lista-configuracion {
  list-style-type: none;
  margin-bottom: 0;
  margin-top: 1rem;
  padding-left: 0;

  @media screen and (min-width: 576px) {
    display: flex;
    flex-flow: row wrap;
  }

  li {
    cursor: pointer;
    @media screen and (min-width: 576px) {
      align-items: stretch;
      display: flex;
      width: 50%;
    }
    @media screen and (min-width: 1200px) {width: calc(100% / 3);}
  }

  .contenedor-item {
    align-items: center;
    border: 1px solid #E0E2EC;
    border-radius: 7px;
    box-shadow: none;
    color: var(--terciario, $terciario);
    display: flex;
    flex-flow: row nowrap;
    margin: .5rem;
    padding: .75rem 1rem;
    transform: scale(1);
    transition-property: box-shadow, color;
    transition-duration: .25s;
    transition-timing-function: ease;
    user-select: none;

    @media screen and (min-width: 576px) {width: 100%;}

    &:hover {
      box-shadow: 3px 3px 10px rgba(224, 226, 236,0.5);
      color: var(--terciario-tinte, $terciario-tinte);
    }
    &.disabled {color: $gris-thead-borde;}

    .icono {
      height: 30px;
      margin-right: .75rem;
      width: 30px;
    }
  }
}
</style>
