<template>
  <mdb-modal
    centered
    elegant
    scrollable
    :show="mostrarModal"
    @close="$emit('cerrar', false);
      limpiarCampos();"
  >
    <mdb-modal-header>
      <mdb-modal-title>
        Cambio de clave
      </mdb-modal-title>
    </mdb-modal-header>
    <mdb-modal-body>
      <div class="row justify-content-center">
        <div
          :class="['col-12 col-md-6',
            {'campo': validarValores.clave
              && validarValores.clave.length},
            {'valido': validarValores.clave == 'valido'},
            {'invalido': validarValores.clave == 'invalido'},
          ]"
        >
          <mdb-input
            v-model.trim="clave"
            :type="mostrarCamposClave.clave ? 'text' : 'password'"
            minlength="8"
            label="Clave"
            class="my-2"
            outline
          >
            <mdb-btn
              slot="append"
              group
              flat
              dark-waves
              size="md"
              :icon="mostrarCamposClave.clave ? 'eye-slash' : 'eye'"
              :title="mostrarCamposClave.clave ? 'Ocultar clave' : 'Mostrar clave'"
              @click="mostrarCamposClave.clave = !mostrarCamposClave.clave"
            />
          </mdb-input>
          <p
            v-if="validarValores.clave == 'invalido'"
            class="mensaje-invalido"
          >
            Indica la clave
          </p>
        </div>
        <div
          :class="['col-12 col-md-6',
            {'campo': validarValores.confirmacionClave
              && validarValores.confirmacionClave.length},
            {'valido': validarValores.confirmacionClave == 'valido'},
            {'invalido': validarValores.confirmacionClave == 'invalido'},
          ]"
        >
          <mdb-input
            v-model.trim="confirmacionClave"
            :type="mostrarCamposClave.confirmacionClave ? 'text' : 'password'"
            minlength="8"
            label="Confirmar clave"
            class="my-2"
            outline
            >
              <mdb-btn
                slot="append"
                group
                flat
                dark-waves
                size="md"
                :icon="mostrarCamposClave.confirmacionClave ? 'eye-slash' : 'eye'"
                :title="mostrarCamposClave.confirmacionClave
                  ? 'Ocultar confirmación de la clave' : 'Mostrar confirmación de la clave'"
                @click="mostrarCamposClave.confirmacionClave
                  = !mostrarCamposClave.confirmacionClave"
              />
            </mdb-input>
          <p
            v-if="validarValores.confirmacionClave == 'invalido'"
            class="mensaje-invalido"
          >
            Confirma la clave
          </p>
        </div>
        <mdb-btn
          color="primario"
          :icon="botonDeshabilitado ? 'circle-notch' : 'sync'"
          :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
          class="col-auto mt-4 px-3"
          :disabled="botonDeshabilitado"
          @click="validarClave"
        >
          Cambiar
        </mdb-btn>
      </div>
    </mdb-modal-body>
  </mdb-modal>
</template>
<script>
import usuarioCambiarClaveGql from '@/graphql/usuarioCambiarClave.gql'
import {
  mdbBtn,
  mdbModal,
  mdbModalBody,
  mdbModalHeader,
  mdbModalTitle,
  mdbInput
} from 'mdbvue'

export default {
  name: 'NuevoCliente',
  props: {
    mostrarModal: {
      type: Boolean,
      required: true
    },
    clienteId: {
      type: String,
      required: false,
      default: ''
    }
  },
  components: {
    mdbBtn,
    mdbModal,
    mdbModalBody,
    mdbModalHeader,
    mdbModalTitle,
    mdbInput
  },
  data () {
    return {
      botonDeshabilitado: false,
      validarValores: {},
      clave: '',
      confirmacionClave: '',
      mostrarCamposClave: {
        clave: false,
        confirmacionClave: false
      }
    }
  },
  watch: {
    mostrarModal: function () {
      if (!this.mostrarModal) {
        this.limpiarCampos()
        this.botonDeshabilitado = false
      }
    }
  },
  methods: {
    alertaMensaje (contenido, tipo) {
      this.$emit('alertaMensaje', {
        contenido,
        tipo
      })
    },
    validarClave () {
      this.validarValores = {
        clave: this.clave.trim() ? 'valido' : 'invalido',
        confirmacionClave: this.confirmacionClave.trim() ? 'valido' : 'invalido'
      }
      // Verifica si existe algún dato invalido
      if (Object.values(this.validarValores).includes('invalido')) {
        this.alertaMensaje('Todos los campos son requeridos', 'error')
        this.botonDeshabilitado = false
        return
      }
      if (this.clave.length < 8) {
        return this.alertaMensaje('La clave debe contener al menos 8 caracteres',
          'advertencia')
      }
      if (this.clave !== this.confirmacionClave) {
        this.validarValores.confirmacionClave = 'invalido'
        return this.alertaMensaje('La confirmación de la clave no coincide. Por favor verifica tus datos',
          'advertencia')
      }
      this.usuarioCambiarClave()
    },
    usuarioCambiarClave () {
      this.botonDeshabilitado = true
      this.$apollo
        .mutate({
          mutation: usuarioCambiarClaveGql,
          variables: {
            personaId: this.clienteId,
            clave: this.clave
          }
        })
        .then(({ data: { usuarioCambiarClave } }) => {
          const msg = usuarioCambiarClave.codigo
          const usuarioConFormato = {}
          switch (msg) {
            case 'Correcto':
              this.alertaMensaje('Se ha modificado la contraseña con éxito.', 'correcto')
              this.limpiarCampos()
              this.botonDeshabilitado = false
              this.$emit('editado', JSON.parse(JSON.stringify(usuarioConFormato)))
              this.$emit('cerrar', false)
              break
            case 'Fallido':
              this.alertaMensaje('Ha ocurrido un error modificando la contraseña.' +
                    ' Por favor intenta de nuevo', 'error')
              this.botonDeshabilitado = false
              break
            default:
              this.alertaMensaje('Ha ocurrido un error inesperado. Por favor intenta de nuevo',
                'error')
              this.botonDeshabilitado = false
              break
          }
        })
        .catch(() => {
          this.alertaMensaje('Ha ocurrido un error inesperado. Por favor revisa tus datos e intenta nuevamente',
            'error')
          this.botonDeshabilitado = false
        })
    },
    limpiarCampos () {
      this.clave = ''
      this.confirmacionClave = ''
      this.validarValores = {}
      this.mostrarCamposClave = {
        clave: false,
        confirmacionClave: false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.campo {
  margin-bottom: 1.1rem;
  &.invalido > .mensaje-invalido.prepend  {left: 47px;}
}
</style>
